<template>
  <div style="height: 100%">
    <div v-if="!isLoading">
      <transition name="slide-fade">
        <div v-if="!isEditMode">
          <h4 class="mb-4 midblue-text" v-bind:style="$store.getters.elementColourBlockText">{{ $store.getters.policyOwner }}, here's a summary of your cover, you can amend and update results below...</h4>
          <quick-change v-bind:isViewMode.sync="isViewMode" />
          <h4 v-if="this.$store.state.pmi.quoteResults.length !== 0" class="mb-4 header-text" v-bind:style="$store.getters.writingColourBlockText">{{ $store.getters.policyOwner }}, here are your results...</h4>
          <result-list v-bind:isEditMode.sync="isEditMode" v-bind:isViewMode.sync="isViewMode"/>  
        </div>
      </transition>

      <transition name="slide-fade">
        <div v-if="isEditMode">
          <tailor-cover v-bind:isEditMode.sync="isEditMode" v-bind:isViewMode.sync="isViewMode"/>
        </div>
      </transition>
    </div>
    <quote-loading v-if="isLoading"/>
  </div>
</template>

<script>
import ResultList from "@/view/pages/pmi/quote-results/ResultList.vue";
import QuickChange from "@/view/pages/pmi/quote-results/QuickChange.vue";
import QuoteLoading from "@/view/pages/pmi/quote-form/QuoteLoading.vue";
import TailorCover from "@/view/pages/pmi/quote-results/TailorCover.vue";

export default {
  name: "quote-results",
  components: {
    ResultList,
    QuickChange,
    QuoteLoading,
    TailorCover
  },
  computed: {
    isLoading: {
      get() {
        return this.$store.state.pmi.isLoading;
      }
    },
    isViewMode: {
      get() {
        return this.$store.state.pmi.isViewMode;
      },
      set(value) {
        this.$store.commit("updateViewMode", value);
      }
    },
    isEditMode: {
      get() {
        return this.$store.state.pmi.isEditMode;
      },
      set(value) {
        this.$store.commit("updateEditMode", value);
      }
    }
  },
  mounted() {
    this.$resizeFrame();
  }
}
</script>

<style>
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>