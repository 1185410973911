<template>
  <div class="result-item mb-4">
    <div class="result-item-border mb-1" v-bind:style="$store.getters.moreLessBorder">
      <b-row class="m-0 p-0">
        <b-col xl="2" lg="4" md="6" class="p-3 d-flex align-items-center">
          <div class="flex-grow-1">
            <div class="mb-2 text-center"><b-img fluid :src="logo" class="insurer-logo"></b-img></div>
            <div class="product-name text-center font-weight-bolder">{{this.product.product}}</div>
          </div>
        </b-col>
        <b-col xl="2" lg="4" md="6" class="p-3 d-flex align-items-center">
          <div class="flex-grow-1">
            <div class="result-underwriting font-weight-bold">{{ product.underwritingLabel }}</div>
            <div class="result-op font-weight-bold" v-bind:style="$store.getters.elementColourBlockText">
              {{product.opLevel}}
            </div>
          </div>
        </b-col>
        <b-col xl="2" lg="4" md="6" class="m-0 p-3 price-container text-center d-flex align-items-center" v-bind:style="$store.getters.optionBox">
          <div class="flex-grow-1">
            <template v-if="product.providerId != 22">
              <div v-bind:style="$store.getters.writingColourBlockText">Per month</div>
              <div v-bind:style="$store.getters.writingColourBlockText" class="price-display font-weight-bold">£{{ product.selectedPrice }}</div>
              <div v-bind:style="$store.getters.writingColourBlockText">*Indicative price</div>
              <div class="promo-text">{{ promoText }}</div>
            </template>

            <template v-if="product.providerId == 22">
              <div v-bind:style="$store.getters.writingColourBlockText">Call</div> 
              <div v-bind:style="$store.getters.writingColourBlockText" class="number-display font-weight-bold">{{$store.getters.phoneNumberText}}</div> 
              <div v-bind:style="$store.getters.writingColourBlockText">for a price.</div>
            </template>
          </div>
        </b-col>
        <b-col xl="2" lg="4" md="6" class="p-3 d-flex align-items-center">
          <div class="flex-grow-1">
            <b-form-group class="result-option mb-2">
              <template v-slot:label>
                <label class="option-label font-weight-bold" v-bind:style="$store.getters.elementColourBlockText">Excess/Co Payment</label>
              </template>
              <b-form-select size="sm" v-bind:style="$store.getters.elementColourBlockBorder" v-model="product.selectedPrice" :options="product.excessOptions"></b-form-select>
            </b-form-group>
            <b-form-group class="result-option mb-2">
              <template v-slot:label>
                <label class="option-label font-weight-bold" v-bind:style="$store.getters.elementColourBlockText">Hospital List</label>
              </template>
              <div class="d-flex">
                <div class="font-weight-bold" v-bind:style="$store.getters.writingColourBlockText">{{ product.selectedHospital }}</div>
                <div class="ml-4">
                  <span v-if="product.providerId != 22" class="edit-hospital pd-3 font-weight-bolder" v-on:click="editCover(product)" v-bind:style="$store.getters.elementColourBlockText">Edit</span>
                </div>
              </div>
            </b-form-group>
          </div>
        </b-col>
        <b-col xl="2" lg="4" md="6" class="cover-details p-3 d-flex align-items-center">
          <div class="flex-grow-1">
            <b-row>
              <b-col cols="6">Inpatient:</b-col>
              <b-col class="text-right font-weight-bolder" v-bind:style="$store.getters.elementColourBlockText">
                {{product.coverDetails.inpatient.covered ? "Yes" : "Not Available"}}
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">Outpatient:</b-col>
              <b-col class="text-right font-weight-bolder" v-bind:style="$store.getters.elementColourBlockText">
                {{product.opLevel}}
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">Cancer Cover:</b-col>
              <b-col class="text-right font-weight-bolder" v-bind:style="$store.getters.elementColourBlockText">
                {{product.coverDetails.heart_cancer.covered ? "Yes" : "NHS"}}
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">Mental Health:</b-col>
              <b-col class="text-right font-weight-bolder" v-bind:style="$store.getters.elementColourBlockText">
                {{product.coverDetails.mental.covered ? "Yes" : "Not Available"}}
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">Therapies:</b-col>
              <b-col class="text-right font-weight-bolder" v-bind:style="$store.getters.elementColourBlockText">
                {{product.coverDetails.therapy.covered ? "Yes" : "Not Available"}}
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">Dental/Optical:</b-col>
              <b-col class="text-right font-weight-bolder" v-bind:style="$store.getters.elementColourBlockText">
                {{product.coverDetails.dental.covered ? "Yes" : "Not Available"}}
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col xl="2" lg="4" md="6" class="p-3 align-items-center d-flex">
          <template v-if="product.providerId != 22">
            <div class="flex-grow-1">
              <b-button v-if="!$store.getters.externalApplication" class="btn-apply w-100 mb-2" v-on:click="applyProduct(product)" variant="primary" v-bind:style="$store.getters.primaryButtonBox">{{ $store.getters.applyOnlineBtnText }}</b-button>

              <a v-if="$store.getters.externalApplication" target="_blank" class="btn btn-apply btn-white w-100 mb-2" :href="$store.getters.externalApplicationLink" variant="primary" v-bind:style="$store.getters.primaryButtonBox">
                {{ $store.getters.applyOnlineBtnText }}
              </a>
              <b-button class="btn-apply w-100" v-on:click="editCover(product)" variant="primary" v-bind:style="$store.getters.primaryButtonBox">Edit Cover</b-button>
            </div>
          </template>
        </b-col>
      </b-row>
      <transition name="slide-fade">
        <b-row class="m-0 p-4" v-if="product.isActive">
            <b-col xl="6" class="benefit-border-right">
              <div class="d-flex flex-row v-align-center justify-content-start mb-3">
                <div class="pl-2 font-weight-bolder">
                  <span>Day/Inpatient</span>
                </div>
                <div class="ml-4 inpatient-icon" :id="`inpatient-detail`">
                  <b-iconstack font-scale="1.5">
                    <b-icon stacked icon="circle-fill" v-bind:style="$store.getters.infoBubble"></b-icon>
                    <b-icon stacked icon="info" variant="light"  scale="0.9"></b-icon>
                  </b-iconstack>
                </div>
              </div>
              <b-popover
                ref="popover"
                :target="`inpatient-detail`"
                triggers="click"
                custom-class="quote-popover"
              >
                <template #title></template>
                <div class="popover-highlight" v-bind:style="$store.getters.infoBubbleBackground"></div>
                <div class="p-3">
                  <p class="mb-0"><strong>Day/Inpatient</strong> – Covers any treatment related to staying overnight or a few hours in hospital. This could be surgeon fees, diagnostic tests such as blood tests, x-rays or ultrasounds as part of the treatment.</p>
                </div>
              </b-popover>
              <div class="accordion rq-accordion" id="inpatient-accordion" role="tablist">
                <template v-for="(keyFeature, i) in product.keyFeatures.inpatient">
                <b-card no-body class="mb-1" :key="i">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-row>
                      <b-col v-b-toggle="`inpatient-accordion-${i}`">
                        <b-icon v-if="!keyFeature.hasOwnProperty('excluded')" icon="check" class="mr-3 accordion-check" font-scale="1"></b-icon>
                        <b-icon v-if="keyFeature.hasOwnProperty('excluded')" icon="x" class="mr-3 accordion-wrong" font-scale="1"></b-icon>
                        <span>{{keyFeature.section}}</span>
                        <font-awesome-icon icon="angle-down" class="ml-5" />
                      </b-col>
                    </b-row>
                    
                  </b-card-header>
                  <b-collapse :id="`inpatient-accordion-${i}`" accordion="inpatient-accordion" role="tabpanel">
                    <b-card-body>
                      <b-card-text v-bind:style="$store.getters.writingColourBlockText">{{keyFeature.text}}</b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                </template>
              </div>
            </b-col>
            <b-col xl="6">
              <div class="d-flex flex-row v-align-center justify-content-start mb-3">
                <div class="pl-2 font-weight-bolder">
                  <span>Outpatient</span>
                </div>
                <div class="ml-4 inpatient-icon" :id="`outpatient-detail`">
                  <b-iconstack font-scale="1.5">
                    <b-icon stacked icon="circle-fill" v-bind:style="$store.getters.infoBubble"></b-icon>
                    <b-icon stacked icon="info" variant="light"  scale="0.9"></b-icon>
                  </b-iconstack>
                </div>
              </div>
              <b-popover
                ref="popover"
                :target="`outpatient-detail`"
                triggers="click"
                custom-class="quote-popover"
              >
                <template #title></template>
                <div class="popover-highlight" v-bind:style="$store.getters.infoBubbleBackground"></div>
                <div class="p-3">
                  <p><strong>Specialist Fees</strong> – Covers the cost of seeing a consultant.</p>
                  <p><strong>Diagnostic Tests</strong> – Covers initial and follow up blood tests, X-rays and ultrasounds.</p>
                  <p><strong>MRI/CT/PET</strong> – These larger scans can be covered as part of an outpatient benefit.</p>
                  <p class="mb-0"><strong>Physiotherapy</strong> – Outpatient Physiotherapy sessions, these are limited based on certain outpatient levels.</p>
                </div>
              </b-popover>
              <div class="accordion rq-accordion" id="outpatient-accordion" role="tablist">
                <template v-for="(keyFeature, i) in product.keyFeatures.outpatient">
                <b-card no-body class="mb-1" :key="i">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-row>
                      <b-col v-b-toggle="`outpatient-accordion-${i}`">
                        <b-icon v-if="!keyFeature.hasOwnProperty('excluded')" icon="check" class="mr-3 accordion-check" font-scale="1"></b-icon>
                              <b-icon v-if="keyFeature.hasOwnProperty('excluded')" icon="x" class="mr-3 accordion-wrong" font-scale="1"></b-icon>
                        <span>{{keyFeature.section}}</span>
                        <font-awesome-icon icon="angle-down" class="ml-5" />
                      </b-col>
                    </b-row>
                  </b-card-header>
                  <b-collapse :id="`outpatient-accordion-${i}`" accordion="outpatient-accordion" role="tabpanel">
                    <b-card-body>
                      <b-card-text v-bind:style="$store.getters.writingColourBlockText">{{keyFeature.text}}</b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                </template>
              </div>
            </b-col>
        </b-row>
      </transition>
    </div>
    <b-row>
        <b-col>
          <b-button variant="primary" class="w-100 zero-radius" v-if="!product.isActive" v-on:click="openDetail" v-bind:style="$store.getters.moreLessBox">More Info<b-icon icon="caret-right-fill" font-scale="1"></b-icon></b-button>
          <b-button variant="primary" class="w-100 zero-radius" v-if="product.isActive" v-on:click="closeDetail" v-bind:style="$store.getters.moreLessBox">Less Info <b-icon icon="caret-right-fill" font-scale="1"></b-icon></b-button>
        </b-col>
      </b-row>
  </div>
</template>

<script>
var capitalize = require('capitalize');
import _ from "lodash";

export default {
  name: "quote-result-item",
  props: {
    product: Object,
  },
  data() {
    return {
      dummyKeyFeatures: [
        'Lorem ipsum dolor sit amet',
        'Maecenas interdum est ac',
        'In id aliquam erat, eget'
      ],
      hospitalOptions: [
        { value: "local", text: "Local" },
        { value: "countrywide", text: "Countrywide" },
        { value: "countrywide_plus", text: "Extended" }
      ]
    }
  },
  computed: {
    promoText: {
      get(){
        var selectedExcess;
        this.product.excessOptions.forEach(ex => {
          if(ex.value == this.product.selectedPrice){
            selectedExcess = 'excess'+ex.text;
          }  
        });

        if(this.product.excessPromos){
          return this.product.excessPromos[selectedExcess];
        } else {
          return "";
        }
      }
    },
    logo: {
      get() {
        return this.product.logo || "";
      }
    },
    price: {
      get() {
        return this.product.price.excess0 || 0.0;
      }
    },
    includedCover: {
      get() {
        return this.product.coverDetails
          .filter(item => item.covered )
          .map(item => {
            return Object.assign(item, {
              displayLabel: capitalize.words(item.coverType.replace("_"," ")) + " -" + item.summary
            })
          });
      }
    },
    excludedCover: {
      get() {
        return this.product.coverDetails
          .filter(item => !item.covered )
          .map(item => {
            return Object.assign(item, {
              displayLabel: capitalize.words(item.coverType.replace("_"," "))
            })
          });
      }
    }
  },
  methods: {
    applyProduct: function(product){
      product.excessOptions.forEach(ex => {
        if(ex.value == product.selectedPrice){
          product['selectedExcess'] = ex.text;
        }  
      });

      if(this.$store.state.pmi.quoteForm.coverage == 'basic'){
        product["coverageLevel"] = 1;
      } else if(this.$store.state.pmi.quoteForm.coverage == 'intermediate'){
        product["coverageLevel"] = 2;
      } else {
        product["coverageLevel"] = 3;
      }
      
      product['startDate'] = this.$store.state.pmi.quoteForm.coverStartDate;

      this.$store.commit("updateSelectedProduct", product);
      this.$store.commit("updateApplicationDetails", _.cloneDeep(this.$store.state.pmi.quoteForm));
      this.$store.commit("updateQuoteRef","main");
      this.$router.push({ name: 'PMIQuoteApplication'});
    },
    editCover: function(product){
      this.$resetFrame();
      this.$store.commit("updateSelectedProduct", product);
      this.$store.commit("updateMainToEditRef");
      this.$emit('update:isEditMode', true);
      this.$resizeFrame();
    },
    openDetail: function(){
      this.$recordFrame();
      this.$emit('update:isViewMode', true);
      this.product.isActive = true;
      this.$resizeFrame();
    },
    closeDetail: function(){
      this.$emit('update:isViewMode', false);
      this.product.isActive = false;
      this.$revertHeight();
    }
  }
};
</script>

<style lang="scss">
.price-display {
  font-size:40px;
}

.number-display {
  font-size: 18px;
}

.result-item-border {
  border: solid 1px $light-blue;
}

.cover-details {
  font-size: .8em;
}

.product-name {
  font-size: 1em;
}

.insurer-logo {
  max-width:80%;
  max-height: 80px;
}

.option-label {
  font-size: .8em !important;
  font-weight: bold;
  margin-bottom: 0px;
}

.option-label-btn {
  height: 28px;
  padding: 3px 15px;
  text-align: left;
  color: #000 !important;
}

.result-option {
  legend {
    padding-bottom: 2px;
  }

  select {
    height: 28px;
    padding: 3px 15px;
  }
}

.price-container {
  background: #ACACAC;
}

.result-underwriting {
  font-size: 1.3em;
}

.result-op {
  font-size: 1.2em;
}

.rq-accordion {
  .card {
    background-color: transparent;
    border: none !important;

    .card-header {
      background-color: transparent;
    }
  }
}

.accordion-check {
  color: #A2CD8B;
}

.accordion-wrong {
  color: #FF0000;
}

.inpatient-icon {
  cursor: pointer;
}

.edit-hospital {
  font-size: .7em;
  cursor: pointer;
}


@media screen and (min-width: 1200px) {
  .benefit-border-right {
    border-right: solid 1px #F0F0F0;
  }
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.promo-text {
  color: #ff0000;
}

.btn-white {
  color: #FFF !important;
}
</style>