<template>
  <div>
    <b-button variant="primary"
      v-if="!showTailorCover" 
      class="w-100 zero-radius mb-3 d-block d-sm-none" 
      @click="toggleTailorCover"
      v-bind:style="$store.getters.primaryButtonBox"
    >
      Tailor Your Cover<b-icon icon="caret-right-fill" font-scale="1"></b-icon>
    </b-button>
    <b-card 
      v-bind:class="{'d-none d-sm-block': !showTailorCover }"
      class="mb-4 quick-change-box" 
      v-bind:style="$store.getters.optionBox">
      <b-container class="p-1">
        <b-row>
          <b-col xl="4" lg="6" md="6" sm="12">
            <b-form-group class="midblue-text strong" v-bind:style="$store.getters.elementColourBlockText">
              <template v-slot:label>
                <div class="d-flex flex-row v-align-center justify-content-start">
                  <div>
                    <span>
                      Level of outpatient
                      <div class="ml-1 d-inline-block popover-icon" :id="`cover-type-detail`">
                        <b-iconstack font-scale="1.5">
                          <b-icon stacked icon="circle-fill" v-bind:style="$store.getters.infoBubble"></b-icon>
                          <b-icon stacked icon="question" variant="light"  scale="0.9"></b-icon>
                        </b-iconstack>
                      </div>
                    </span>
                  </div>
                  
                </div>
                <b-popover
                  ref="popover"
                  :target="`cover-type-detail`"
                  triggers="click"
                  @show="closeOtherPopover"
                  custom-class="quote-popover"
                >
                  <template #title></template>
                  <div class="popover-highlight" v-bind:style="$store.getters.infoBubbleBackground"></div>
                  <div class="p-3">
                    <p><strong>Budget (No Outpatients)</strong> – covers the cost of any treatment you need while you’re in hospital, as well as the cost of your stay.</p>
                    <p><strong>Limited OP (Mid Range)</strong> – covers treatment, as well as some diagnostic testing. This can include blood tests at private hospitals, consultations with specialists. There would normally be a set amount of cover/limit.</p>
                    <p class="mb-0"><strong>Full OP (Fully Comprehensive)</strong> – covers all your treatment, including diagnostics, consultations that you undergo at private facilities.</p>
                  </div>
                </b-popover>
              </template>
              <b-form-radio-group
                class="rq-toggle d-flex flex-wrap flex-row"
                v-model="coverage"
                name="coverage"
                buttons
              >
                <b-form-radio
                  class="btn-qc flex-grow-0 align-content-center d-flex justify-content-center align-items-center"
                  v-for="(item,i) in coverageOptions" 
                  :key="i" 
                  :value="item.value" 
                  v-bind:style="[item.value == coverage ? $store.getters.buttonBox : {}]"
                >{{item.text}}</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col xl="2" lg="6" md="6" sm="12">
            <b-form-group class="midblue-text strong" label="Your excess" v-bind:style="$store.getters.elementColourBlockText">
              <template v-slot:label>
                <div class="d-flex v-align-center justify-content-start">
                  <div>
                    <span>
                      Your excess
                      <div class="ml-1 d-inline-block popover-icon" :id="`excess-option-detail`">
                        <b-iconstack font-scale="1.5">
                          <b-icon stacked icon="circle-fill" v-bind:style="$store.getters.infoBubble"></b-icon>
                          <b-icon stacked icon="question" variant="light"  scale="0.9"></b-icon>
                        </b-iconstack>
                      </div>
                    </span>
                  </div>
                  
                </div>
                <b-popover
                  :target="`excess-option-detail`"
                  triggers="click"
                  ref="popover"
                  @show="closeOtherPopover"
                  custom-class="quote-popover"
                >
                  <template #title></template>
                  <div class="popover-highlight" v-bind:style="$store.getters.infoBubbleBackground"></div>
                  <div class="p-3">
                    <p class="mb-0"><strong>Excess</strong> – paying the first part of a claim can reduce premiums with a chosen excess</p>
                  </div>
                </b-popover>
              </template>
              <b-form-select
                class="select-qc"
                v-model="excessLevel"
                :options="excessOptions"
                name="excess"
                buttons
                @change="updateSelectedExcess"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col xl="3" lg="6" md="6" sm="12">
            <b-form-group class="midblue-text strong" v-bind:style="$store.getters.elementColourBlockText">
              <template v-slot:label>
                <div class="d-flex flex-row v-align-center justify-content-start">
                  <div>
                    <span>
                      Underwriting type
                      <div class="ml-1 d-inline-block popover-icon" :id="`underwriting-type-detail`">
                        <b-iconstack font-scale="1.5">
                          <b-icon stacked icon="circle-fill" v-bind:style="$store.getters.infoBubble"></b-icon>
                          <b-icon stacked icon="question" variant="light"  scale="0.9"></b-icon>
                        </b-iconstack>
                      </div>
                    </span>
                  </div>
                </div>
                <b-popover
                  ref="popover"
                  :target="`underwriting-type-detail`"
                  triggers="click"
                  @show="closeOtherPopover"
                  custom-class="quote-popover"
                >
                  <template #title></template>
                  <div class="popover-highlight" v-bind:style="$store.getters.infoBubbleBackground"></div>
                  <div class="p-3">
                    <p><strong>Moratorium</strong>– Moratorium underwriting excludes all pre-existing conditions from the last five years for a set period, usually two years, but may then include them after that</p>
                    <p><strong>FMU</strong> – requires complete medical history to be provided for all policyholders and may exclude certain conditions for the lifetime of the policy that have been disclosed.</p>
                    <p><strong>Switch</strong> –  any conditions covered by your existing policy may also be covered by your new policy, ensuring continuous cover.</p>
                    <p class="mb-0">Continued Moratorium – Excludes any medical condition in the last 5 years but will cover the condition if there has been more than 2 years without treatment, medication or advice.</p>
                  </div>
                </b-popover>
              </template>
              <b-form-select
                class="select-qc"
                v-model="underwriting"
                :options="underwritingOptions"
                name="underwriting"
                buttons
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col xl="3" lg="6" md="6" sm="12">
            <b-form-group class="midblue-text strong" v-bind:style="$store.getters.elementColourBlockText">
              <template v-slot:label>
                <div class="d-flex v-align-center justify-content-start">
                  <div>
                    <span>
                      Hospital coverage
                      <div class="ml-1 d-inline-block popover-icon" :id="`hospital-coverage-detail`">
                        <b-iconstack font-scale="1.5">
                          <b-icon stacked icon="circle-fill" v-bind:style="$store.getters.infoBubble"></b-icon>
                          <b-icon stacked icon="question" variant="light"  scale="0.9"></b-icon>
                        </b-iconstack>
                      </div>
                    </span>
                  </div>
                </div>
                <b-popover
                  :target="`hospital-coverage-detail`"
                  triggers="click"
                  ref="popover"
                  @show="closeOtherPopover"
                  custom-class="quote-popover"
                >
                  <template #title></template>
                  <div class="popover-highlight" v-bind:style="$store.getters.infoBubbleBackground"></div>
                  <div class="p-3">
                    <p><strong>Local</strong> – Hospitals in the UK’s largest hospital groups, including BMI Healthcare, Nuffield Health, Spire Healthcare within a 25 - 30 mile radius</p>
                    <p><strong>Countrywide</strong> – includes a comprehensive national list of hospitals, plus they have a comprehensive network within the M25 and central London</p>
                    <p class="mb-0"><strong>Extended</strong> – nationwide, plus an extended Greater and Central London Network.</p>
                  </div>
                </b-popover>
              </template>
              <b-form-radio-group
                class="rq-toggle d-flex flex-wrap flex-row"
                v-model="hospital"
                name="hospital"
                buttons
              >
                <b-form-radio
                  class="btn-qc btn-qc-sm flex-grow-0 d-flex justify-content-center align-items-center"
                  v-for="(item,i) in hospitalOptions" 
                  :key="i" 
                  :value="item.value" 
                  v-bind:style="[item.value == hospital ? $store.getters.buttonBox : {}]"
                >{{item.text}}</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col xl="6" lg="6" md="6" sm="12">
            <b-form-group class="midblue-text strong" v-bind:style="$store.getters.elementColourBlockText">
              <template v-slot:label>
                <div class="d-flex v-align-center justify-content-start">
                  <div>
                    <span>
                      Enhance your cover by adding these optional extras
                      <div class="ml-1 d-inline-block popover-icon" :id="`extra-option-detail`">
                        <b-iconstack font-scale="1.5">
                          <b-icon stacked icon="circle-fill" v-bind:style="$store.getters.infoBubble"></b-icon>
                          <b-icon stacked icon="question" variant="light"  scale="0.9"></b-icon>
                        </b-iconstack>
                      </div>
                    </span>
                  </div>
                  
                </div>
                <b-popover
                  :target="`extra-option-detail`"
                  triggers="click"
                  ref="popover"
                  @show="closeOtherPopover"
                  custom-class="quote-popover"
                >
                  <template #title></template>
                  <div class="popover-highlight" v-bind:style="$store.getters.infoBubbleBackground"></div>
                  <div class="p-3">
                    <p><strong>Alternative Therapies</strong> – Provides treatment for Physiotherapy, Osteopathy, Chiropractic, Homeothapy</p>
                    <p><strong>Mental Health</strong> – Inpatient psychiatric treatment or outpatient mental healthcare and therapies</p>
                    <p class="mb-0"><strong>Optical/Dental</strong> – Provides a set level of cover for routine dental/optical treatment</p>
                  </div>
                </b-popover>
              </template>
              <b-form-checkbox-group
                class="rq-toggle d-flex flex-wrap flex-row"
                v-model="addOns"
                name="addOns"
                buttons
              >
                <b-form-checkbox
                  class="btn-qc flex-grow-0 d-flex justify-content-center align-items-center"
                  v-for="(item,i) in extrasOptions" 
                  :key="i" 
                  :value="item.value" 
                  v-bind:style="[addOns.includes(item.value) ? $store.getters.buttonBox : {}]"
                >{{item.text}}</b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </b-col>
          <b-col xl="6" lg="6" md="6" sm="12">
            <b-form-group class="midblue-text strong" v-bind:style="$store.getters.elementColourBlockText">
              <template v-slot:label>
                <div class="d-flex v-align-center justify-content-start">
                  <div>
                    <span>
                      Reduce your premiums by adding these options
                      <div class="ml-1 d-inline-block popover-icon" :id="`reduce-option-detail`">
                        <b-iconstack font-scale="1.5">
                          <b-icon stacked icon="circle-fill" v-bind:style="$store.getters.infoBubble"></b-icon>
                          <b-icon stacked icon="question" variant="light"  scale="0.9"></b-icon>
                        </b-iconstack>
                      </div>
                    </span>
                  </div>
                  
                </div>
                <b-popover
                  :target="`reduce-option-detail`"
                  triggers="click"
                  ref="popover"
                  @show="closeOtherPopover"
                  custom-class="quote-popover"
                >
                  <template #title></template>
                  <div class="popover-highlight" v-bind:style="$store.getters.infoBubbleBackground"></div>
                  <div class="p-3">
                    <p><strong>Six Weeks</strong> – If the treatment you need is available on the NHS with a wait of less than 6 weeks, you would use the NHS for your treatment rather than private medical care.</p>

                    <p><strong>Guided Option</strong> – Essentially, the guided option is where insurers have negotiated the fees they will pay to consultants and hospitals. The insurer then passes these savings on to you, to benefit from a reduced health insurance premium. Should you be looking for ways to reduce the premium of your renewal, and you either want to remain with the same provider, or you are not able to switch due to medical conditions, you may want to consider this option.</p>
                  </div>
                </b-popover>
              </template>
              <b-form-checkbox-group
                class="rq-toggle d-flex flex-wrap flex-row"
                v-model="reducedPremiums"
                name="reducedPremiumsOptions"
                buttons
              >
                <b-form-checkbox
                  class="btn-qc flex-grow-0 d-flex justify-content-center align-items-center"
                  v-for="(item,i) in reducedPremiumsOptions"
                  :key="i" 
                  :value="item.value" 
                  v-bind:style="[reducedPremiums.includes(item.value) ? $store.getters.buttonBox : {}]"
                >{{item.text}}</b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="font-weight-bold mb-4" v-bind:style="$store.getters.elementColourBlockText">
              Insurers also have specific options so don't forget to hit the edit cover button to see addons/options that apply to that insurer or simply call us to find out more.
            </div>
          </b-col>
        </b-row>
        
        <b-row>
          <b-col>
            <div v-if="$store.getters.hasCustomerAgreement && $store.getters.twoPageQuote">
              <p class="font-weight-bold mb-4" v-bind:style="$store.getters.elementColourBlockText">{{$store.getters.customerAgreeMessage || ""}}</p>
            </div>
            <b-button class="large-button" variant="primary" v-on:click="getPmiQuotes" 
            v-bind:style="$store.getters.primaryButtonBox"
            >{{ $store.getters.twoPageQuote ? "Get Quote" : "Update Quote" }}</b-button>
            <div v-if="$store.getters.twoPageQuote" class="mt-10">
              <a v-bind:style="$store.getters.elementColourBlockText" v-if="$store.getters.privacyLink" :href="$store.getters.privacyLink" target="_blank" class="mr-5 font-weight-bold">Privacy Policy</a>
              <a v-bind:style="$store.getters.elementColourBlockText" v-if="$store.getters.tosLink" :href="$store.getters.tosLink" target="_blank" class="font-weight-bold">Terms and Condition</a>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
    <b-button variant="primary" 
      v-if="showTailorCover" 
      class="w-100 zero-radius mb-3 d-block d-sm-none" 
      @click="toggleTailorCover"
      v-bind:style="$store.getters.primaryButtonBox"
    >
      Hide This<b-icon icon="caret-right-fill" font-scale="1"></b-icon>
    </b-button>
  </div>
  
</template>

<script>
export default {
  name: "quote-change",
  props: ['isViewMode'],
  data() {
    return {
      showTailorCover: false,
      resizeConfig: {ratio: 0.8, minFontSize: '1px', maxFontSize: '14px', delay: 200},
      coverageOptions: [
        { text: "Budget", value: "basic" },
        { text: "Mid Range", value: "intermediate" },
        { text: "Fully Comp", value: "full" }
      ],
      excessOptions: [
        { value: 0, text: "£0" },
        { value: 100, text: "£100" },
        { value: 150, text: "£150" },
        { value: 200, text: "£200" },
        { value: 250, text: "£250" },
        { value: 300, text: "£300" },
        { value: 500, text: "£500" },
        { value: 750, text: "£750" },
        { value: 1000, text: "£1000" },
        { value: 2000, text: "£2000" }
      ],
      underwritingOptions: [
        { value: "NMORI", text: "Moratorium" },
        // { value: "CMORI", text: "Continued Moratorium" },
        { value: "CPME", text: "Switch" },
        { value: "FMU", text: "Full Medical Underwriting" }
      ],
      hospitalOptions: [
        { value: "local", text: "Local" },
        { value: "countrywide", text: "Countrywide" },
        { value: "countrywide_plus", text: "Extended" }
      ],
      ynOptions: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 }
      ],
      extrasOptions: [
        { text: "Therapies", value: "treatment" },
        { text: "Mental Health", value: "psychiatric" },
        { text: "Optical/Dental", value: "dental" }
      ],
      reducedPremiumsOptions: [
        { text: "Six Week Wait", value: "sixWeek" },
        { text: "Guided Option", value: "guidedOption" }
      ]
    };
  },
  computed: {
    underwritingError: {
      get(){
        return "You will need to go back to Your Details to confirm details of your current policy, if you need help please call "+this.$store.getters.phoneNumberText+".";
      }
    },
    coverage: {
      get() {
        return this.quoteForm.coverage;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.coverage = value;
        this.$store.commit("updateQuoteForm", quoteForm);
      }
    },
    underwriting: {
      get() {
        console.log(this.quoteForm.underwriting);
        return this.quoteForm.underwriting;
      },
      set(value) {
        if(value == 'CPME' && !this.quoteForm.hasExistingPolicy){
          this.$swal.fire({
            position: 'top',
            showClass: {
              popup: 'swal2-show mt-25',
            },
            text: this.underwritingError,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: this.$store.getters.primaryButtonBox.backgroundColor,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Edit Details'
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push({ name: 'PMIQuoteForm' });
            }
          });
        }
        
        let quoteForm = { ...this.quoteForm };
        quoteForm.underwriting = value;
        this.$store.commit("updateQuoteForm", quoteForm);
      }
    },
    excessLevel: {
      get() {
        return this.quoteForm.excessLevel;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.excessLevel = value;
        this.$store.commit("updateQuoteForm", quoteForm);
      }
    },
    coverStartDate: {
      get() {
        return this.quoteForm.coverStartDate;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.coverStartDate = value;
        this.$store.commit("updateQuoteForm", quoteForm);
      }
    },
    hospital: {
      get() {
        return this.quoteForm.hospital;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.hospital = value;
        this.$store.commit("updateQuoteForm", quoteForm);
      }
    },
    addOns: {
      get() {
        var addOns = [];
        if(this.quoteForm.dental){
          addOns.push("dental");
        }

        if(this.quoteForm.psychiatric){
          addOns.push("psychiatric");
        }

        if(this.quoteForm.treatment){
          addOns.push("treatment");
        }
        return addOns;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        
        quoteForm.psychiatric = value.includes("psychiatric") ? 1 : 0;
        
        quoteForm.treatment = value.includes("treatment") ? 1 : 0;
        quoteForm.bupaTherapy = value.includes("treatment") ? 1 : 0;

        quoteForm.dental = value.includes("dental") ? 1 : 0;
        quoteForm.bupaDental = value.includes("dental") ? 1 : 0;
         

        this.$store.commit("updateQuoteForm", quoteForm);
      }
    },
    reducedPremiums: {
      get() {
        var reducedPremiums = [];

        if(this.quoteForm.sixWeek){
          reducedPremiums.push("sixWeek");
        }

        if(this.quoteForm.guidedOption){
          reducedPremiums.push("guidedOption");
        }
        return reducedPremiums;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        
        quoteForm.sixWeek = value.includes("sixWeek") ? 1 : 0;
        quoteForm.guidedOption = value.includes("guidedOption") ? 1 : 0;

        this.$store.commit("updateQuoteForm", quoteForm);
      }
    },
    hasDependents: {
      get() {
        return this.quoteForm.hasDependents;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.hasDependents = value;
        this.$store.commit("updateQuoteForm", quoteForm);
      }
    },
    quoteForm: {
      get() {
        return this.$store.state.pmi.quoteForm;
      }
    }
  },
  methods: {
    getPmiQuotes: function(){
      if(this.quoteForm.underwriting == 'CPME' && !this.quoteForm.hasExistingPolicy){
        this.$swal.fire({
          position: 'top',
          showClass: {
            popup: 'swal2-show mt-25',
          },
          text: this.underwritingError,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: this.$store.getters.primaryButtonBox.backgroundColor,
          cancelButtonColor: '#d33',
          confirmButtonText: 'Edit Details'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: 'PMIQuoteForm' });
          }
        });
      } else  {
        this.$resetFrame();
        this.$store.commit("updateLoadingStatus", true);
        this.$emit('update:isViewMode', false);

        this.$store.dispatch("getQuoteProducts").then(quotes => {
          var messageType = "updateResult";
          if(this.$store.getters.twoPageQuote){
            messageType = 'quoteResults';
          }
          
          var pluginMessage = { 
            messageType: messageType,
            payload: {
              clientDetails: this.$formatPmi(this.quoteForm),
              quoteResult: quotes
            }
          };

          // Transmit to parent window.
          window.parent.postMessage(JSON.stringify(pluginMessage),"*");

          this.$resizeFrame();
          this.$store.commit("updateLoadingStatus", false);
        })
        .catch(() => {
          this.$store.commit("updateLoadingStatus", false);
        });
      }
    },
    closeOtherPopover: function(){
      this.$root.$emit('bv::hide::popover')
    },
    updateSelectedExcess: function(){
      this.$store.commit("changeExcess");
      this.$store.commit("sortQuotes");
    },
    toggleTailorCover: function(){
      console.log("Toggle tailor cover");
      this.showTailorCover = !this.showTailorCover;
      this.$resizeFrame();
    }
  },
  created(){
    if(this.$store.getters.twoPageQuote){
      this.showTailorCover = true;  
    }

    document.getElementsByTagName('body')[0].addEventListener('click', () => {
      this.$root.$emit('bv::hide::popover')
    });
  }
}
</script>

<style lang="scss">
.quote-popover {
  min-width: 200px;

  .popover-body {
    padding: 0;
  }

  .popover-highlight {
    width: 100%; 
    height: 5px; 
    background-color: #FF0000; 
    border-radius: 5px 5px 0 0;
    margin-top: -1px;
  }
}

.btn-qc {
  height: 30px !important;
  padding: 3px 5px !important;
  width: 90px;
  font-size: 10px !important;
  margin-bottom: 5px !important;
}

.select-qc {
  height: 30px !important;
  padding-top: 5px;
  padding-top: 5px;
}

.btn-qc-sm {
  width: 75px;
}

.quick-change-box {
  .card-body {
    padding: 15px;
  }
}


.btn-qc.btn-secondary.focus:not(.btn-text){
  background-color: transparent;
}

.popover-icon {
  cursor: pointer;
}
</style>
